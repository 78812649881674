<template>
  <ion-page>
    <template v-if="game">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
          <ion-title>{{ game.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
        <div id="container">
          <div class="info">
              {{ game.description }}
          </div>

          <div class="config">
            <ClubSelect v-if="game.configs.club" v-model="innerGameConfig.club" :clubs="game.configs.club" :readOnly="readOnly" />

            <DistanceSelect v-if="game.configs.distance" v-model="innerGameConfig.distance" :distances="game.configs.distance" :readOnly="readOnly" />
          </div>
        </div>
      </ion-content>

      <ion-footer>
        <ion-toolbar class="footer-toolbar">
          <ion-button v-if="!readOnly" expand="full" size="large" @click="onStartGame">
            Start Game
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </template>
  </ion-page>
</template>

<script>
import {
  IonButtons, 
  IonContent, 
  IonHeader, 
  IonMenuButton,
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonButton,
  IonFooter,
} from '@ionic/vue';
import { golfSharp } from 'ionicons/icons';
import ClubSelect from "@/components/ClubSelect";
import DistanceSelect from "@/components/DistanceSelect";

export default {
  name: 'GameConfigure',
  inject: ['global'],
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonFooter,

    ClubSelect,
    DistanceSelect,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },

    gameConfig: {
      type: Object,
      default: () => ({
        club: null,
        distance: null,
      }),
    },
  },

  setup() {

    return {
      golfSharp
    };
  },

  created() {
    this.innerGameConfig = { ...this.gameConfig };
  },

  data() {
    return {
      innerGameConfig: {},
    };
  },

  computed: {
    gameId() {
      return parseInt(this.$route.params.id, 10);
    },

    game() {
      return this.global.games.getById(this.gameId);
    },
  },

  methods: {
    onStartGame() {
      const gameSession = this.global.gameSessions.createGameSession(this.gameId, null, null, this.innerGameConfig);

      this.$router.push({
        name: 'GameSession',
        params: {
          id: gameSession.id,
        },
      });
    },
  },
}
</script>

<style scoped lang="scss">
  ion-icon {
    margin-right: 1rem;
  }

  .info {
    margin: 2rem 2rem 1rem;
    font-weight: bold;
    text-align: center;
  }

  .config {
    margin: 2rem 1rem;
  }
</style>